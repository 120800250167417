html, body {
  background: linear-gradient(0deg, rgba(23,20,19,1) 0%, rgba(31,12,12,1) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.App {
  text-align: center;
}

.dialpad {
  margin: 0 auto;
  width: 400px;
  height: 600px;
  /* background-color: red; */
  padding-top: 50px;
  color: white;
  font-size: 45px;
  text-align: center;
  user-select: none;
}

.sidebar {
  width: 250px;
  float: left;
  font-size: xx-large;
  color: white;
  padding: 20px;
}

.dial-button {
  width: 90px;
  height: 90px;
  background-color: #141212;
  float: left;
  margin: 18px;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-style: solid;
  border-color: white;
}

.dial-button:hover {
  background-color: #201b1b;
  cursor: pointer;
}

.dial-button:active {
  background-color: #422a2a;
}

.call-button {
  width: 100%;
  height: 70px;
  background-color: #141212;
  float: left;
  margin-top: 18px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radisu: 15px;
  border-style: solid;
  border-color: white;
}

.call-button:hover {
  background-color: #201b1b;
  cursor: pointer;
}

.call-button:active {
  background-color: #422a2a;
}

.bj-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-hidden {
  display: none;
}
.page-show {
  display: block;
}